import React from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../context/LanguageContext';
import './BlogCard.css';

interface BlogCardProps {
  post: string;
  href?: string;
}

export const BlogCard: React.FC<BlogCardProps> = ({ post, href }) => {
  const { translations, language } = useLanguage();
  const blogPost = translations?.[language]?.blogPosts?.[post];

  if (!blogPost) return null;

  const content = (
    <div className="blog-card">
      <img 
        src={`/images/blog/${post}/preview.png`} 
        alt={blogPost.title} 
        className="blog-image"
      />
      <h3>{blogPost.title}</h3>
      <p>{blogPost.description}</p>
    </div>
  );

  if (href) {
    return (
      <Link to={href} className="blog-link">
        {content}
      </Link>
    );
  }

  return content;
}; 