import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useLanguage } from '../../context/LanguageContext';
import { LanguageSwitcher } from '../LanguageSwitcher';
import './BlogPostPage.css';
import { Helmet } from 'react-helmet';

export const BlogPostPage: React.FC = () => {
  const { postId = '' } = useParams();
  const navigate = useNavigate();
  const { translations, language } = useLanguage();
  
  // Створюємо мапу для відповідності URL до ID постів
  const urlToPostId: Record<string, string> = {
    'what-is-nps-how-to-calculate-and-use-it': 'first_post',
    'building-saas-products': 'second_post',
    'enps-measuring-team-satisfaction': 'third_post'
  };
  
  // Отримуємо актуальний ID поста з мапи або використовуємо оригінальний postId
  const actualPostId = urlToPostId[postId] || postId;
  const post = translations?.[language]?.blogPosts?.[actualPostId];
  
  useEffect(() => {
    if (!post) {
      navigate(`/blog?lang=${language}`);
      return;
    }
  }, [post, navigate, language]);

  console.log('Current URL:', window.location.pathname);
  console.log('Params postId:', postId);
  console.log('Language:', language);
  console.log('Available posts:', translations?.[language]?.blogPosts);

  const handleBack = () => {
    navigate(`/blog?lang=${language}`);  // Добавляем параметр языка
  };

  const authorName = translations?.[language]?.authorName || 'Anton Kovalenko';

  useEffect(() => {
    if (post?.title) {
      document.title = `${post.title} | ${authorName}`;
    }
  }, [post?.title, authorName]);

  if (!post) return null;

  return (
    <>
      <Helmet>
        <link 
          rel="canonical" 
          href={`https://antonkovalenko.com${window.location.pathname}`} 
        />
        <meta property="og:url" content={`https://antonkovalenko.com${window.location.pathname}`} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={`${post.title} | ${authorName}`} />
        <meta property="og:description" content={post.description} />
      </Helmet>
      <button 
        className="back-button" 
        onClick={handleBack}
      >
        {translations?.[language]?.back || 'Back'}
      </button>
      <LanguageSwitcher />
      <div className="blog-post">
        <h1>{post.title}</h1>
        <img 
          src={`/images/blog/${actualPostId}/preview.png`}
          alt={`${post.title} preview`}
          className="post-image"
        />
        <div 
          className="post-content"
          dangerouslySetInnerHTML={{ __html: post.content }}
        />
      </div>
    </>
  );
}; 