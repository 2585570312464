import React, { createContext, useContext, useState } from 'react';
import { translations } from '../data/translations/index';
import { Language, Translations } from '../types';
import { updateLanguageInUrl } from '../utils/url';

interface LanguageContextType {
  language: Language;
  setLanguage: (lang: Language) => void;
  toggleLanguage: () => void;
  translations: Translations;
}

const LanguageContext = createContext<LanguageContextType>({
  language: 'en',
  setLanguage: () => {},
  toggleLanguage: () => {},
  translations
});

export const LanguageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [language, setLanguage] = useState<Language>(() => {
    const urlParams = new URLSearchParams(window.location.search);
    return (urlParams.get('lang') === 'ua' ? 'ua' : 'en') as Language;
  });

  const toggleLanguage = () => {
    const newLanguage = language === 'en' ? 'ua' : 'en' as Language;
    setLanguage(newLanguage);
    updateLanguageInUrl(newLanguage);
  };

  const value: LanguageContextType = {
    language,
    setLanguage,
    toggleLanguage,
    translations
  };

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
}; 