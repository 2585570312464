import React from 'react';
import { useLanguage } from '../../context/LanguageContext';
import { ProjectId } from '../../types';
import './ProjectCard.css';
// Импортируем изображения напрямую
import project1 from '../../assets/images/project1.png';
import project2 from '../../assets/images/project2.png';
import project3 from '../../assets/images/project3.png';

interface ProjectCardProps {
  project: ProjectId;
  href?: string;
}

const getImagePath = (project: string): any => {
  switch (project) {
    case 'zapytai':
      return project1;
    case 'yaktobi':
      return project2;
    case 'inprogress':
      return project3;
    default:
      return project1;
  }
};

export const ProjectCard = ({ project, href }: ProjectCardProps) => {
  const { translations, language } = useLanguage();
  const projectData = translations[language].projectCards[project];

  const Card = (
    <div className="project-card">
      <h3 className="project-title">{projectData.title}</h3>
      <p className="project-description">{projectData.description}</p>
      <img 
        src={getImagePath(project)}
        alt={`${projectData.title} Project`} 
        className="project-image"
      />
    </div>
  );

  return href ? (
    <a href={href} className="project-link">
      {Card}
    </a>
  ) : Card;
}; 