import { Translation } from '../../types';

export const en: Translation = {
  authorName: "Anton Kovalenko",
  projects: "Projects",
  blog: "Blog",
  profile: {
    title: "Anton Kovalenko | Building products",
    description: "Turning ideas into SaaS products in MarTech | Obsessed with startups, crypto, and the latest in tech.",
    followLinkedIn: "Follow my LinkedIn profile:"
  },
  projectCards: {
    zapytai: {
      title: "ZapytAI",
      description: "Your assistant for finding answers. Upload CSV and Excel files and get quick, accurate responses to your questions."
    },
    yaktobi: {
      title: "Yaktobi",
      description: "Our service helps analyze the customer journey and gather in-depth analytics for both online and offline businesses."
    },
    inprogress: {
      title: "In progress",
      description: "A new project is in development. Stay tuned for updates to learn more about upcoming opportunities and features."
    }
  },
  blogPosts: {
    first_post: {
      title: "What is NPS: How to calculate and use it.",
      description: "The Net Promoter Score (NPS) is a metric that measures the level of customer loyalty to a brand.",
      content: `
        <h2>Understanding NPS</h2>
        <p>Net Promoter Score (NPS) is a widely used customer loyalty metric that measures customers' willingness to recommend a company's products or services to others. It serves as a proxy for gauging customer satisfaction and loyalty.</p>

        <h2>How to Calculate NPS</h2>
        <p>NPS is calculated based on responses to a single question: "How likely are you to recommend our product/service to a friend or colleague?" Respondents give a rating between 0 (not at all likely) and 10 (extremely likely).</p>
        
        <p>Based on their responses, customers are categorized into three groups:</p>
        <ul>
          <li>Promoters (score 9-10): Loyal enthusiasts who will keep buying and refer others</li>
          <li>Passives (score 7-8): Satisfied but unenthusiastic customers</li>
          <li>Detractors (score 0-6): Unhappy customers who can damage your brand</li>
        </ul>

        <p>The NPS is calculated by subtracting the percentage of Detractors from the percentage of Promoters:</p>
        <p>NPS = % Promoters - % Detractors</p>

        <h2>Why NPS Matters</h2>
        <p>NPS is valuable because it provides a clear and actionable metric that correlates with business growth. Companies with high NPS scores typically outperform their competitors in terms of revenue growth and customer retention.</p>

        <h2>Best Practices for NPS Surveys</h2>
        <p>1. Keep it simple and consistent</p>
        <p>2. Survey customers at relevant touchpoints</p>
        <p>3. Follow up with open-ended questions</p>
        <p>4. Act on the feedback received</p>
        <p>5. Track changes over time</p>

        <h2>Using NPS Data</h2>
        <p>The real value of NPS comes from what you do with the data. Use it to:</p>
        <ul>
          <li>Identify areas for improvement</li>
          <li>Predict customer churn</li>
          <li>Drive product development</li>
          <li>Benchmark against competitors</li>
        </ul>
      `
    },
    second_post: {
      title: "Building SaaS Products",
      description: "Key insights and lessons learned from building successful SaaS products.",
      content: `
        <h2>Understanding SaaS Fundamentals</h2>
        <p>Software as a Service (SaaS) has revolutionized how businesses deliver value to customers. Instead of one-time purchases, SaaS operates on a subscription model, providing continuous service and regular updates. This model requires a different approach to both development and metrics tracking.</p>

        <h2>Essential Metrics for SaaS Startups</h2>
        <p>When launching a SaaS product, these key metrics should be your primary focus:</p>

        <h3>1. Monthly Recurring Revenue (MRR)</h3>
        <p>MRR is the lifeblood of any SaaS business. It represents your predictable monthly revenue stream and helps in forecasting growth. Track:</p>
        <ul>
          <li>New MRR from new customers</li>
          <li>Expansion MRR from existing customers upgrading</li>
          <li>Churned MRR from cancellations</li>
          <li>Net MRR growth rate</li>
        </ul>

        <h3>2. Customer Acquisition Cost (CAC)</h3>
        <p>Understanding how much it costs to acquire each customer is crucial. Calculate:</p>
        <ul>
          <li>Total marketing and sales costs</li>
          <li>Cost per lead</li>
          <li>Conversion rate from lead to customer</li>
          <li>Time to recover CAC (should be less than 12 months)</li>
        </ul>

        <h3>3. Customer Lifetime Value (CLV)</h3>
        <p>CLV helps determine how much you can spend on acquisition while remaining profitable:</p>
        <ul>
          <li>Average subscription length</li>
          <li>Average revenue per user (ARPU)</li>
          <li>Upsell and cross-sell opportunities</li>
          <li>CLV/CAC ratio (aim for 3:1 or better)</li>
        </ul>

        <h3>4. Churn Rate</h3>
        <p>Monitor both customer and revenue churn:</p>
        <ul>
          <li>Monthly customer churn rate</li>
          <li>Revenue churn rate</li>
          <li>User engagement before churning</li>
          <li>Reasons for cancellation</li>
        </ul>

        <h2>Product Development Strategy</h2>
        <p>Successful SaaS products require a balanced approach to development:</p>
        <ol>
          <li>Start with a Minimum Viable Product (MVP)</li>
          <li>Focus on core features that solve specific problems</li>
          <li>Implement continuous feedback loops</li>
          <li>Plan for scalability from day one</li>
          <li>Prioritize user experience and onboarding</li>
        </ol>

        <h2>Customer Success Focus</h2>
        <p>In SaaS, customer success is crucial for sustainable growth:</p>
        <ul>
          <li>Develop a comprehensive onboarding process</li>
          <li>Provide excellent customer support</li>
          <li>Create educational content and resources</li>
          <li>Regular check-ins with customers</li>
          <li>Monitor usage patterns and engagement</li>
        </ul>

        <h2>Common Challenges and Solutions</h2>
        <p>Be prepared to address these common challenges:</p>
        <ul>
          <li>Feature creep - Stay focused on core value proposition</li>
          <li>Technical debt - Plan regular maintenance sprints</li>
          <li>Pricing strategy - Start simple, adjust based on value</li>
          <li>Customer retention - Focus on ongoing value delivery</li>
        </ul>

        <h2>Conclusion</h2>
        <p>Building a successful SaaS product requires careful attention to both metrics and customer needs. Focus on delivering consistent value, measuring the right metrics, and maintaining a strong feedback loop with your users. Remember that success in SaaS is a marathon, not a sprint.</p>
      `
    },
    third_post: {
      title: "eNPS: Measuring Team Satisfaction",
      description: "How to measure and improve employee satisfaction using the eNPS methodology in your organization.",
      content: `
        <h2>What is eNPS?</h2>
        <p>Employee Net Promoter Score (eNPS) is an adaptation of the traditional NPS methodology designed to measure employee satisfaction and loyalty. It helps organizations understand how their employees feel about their workplace and identify areas for improvement in company culture.</p>

        <h2>How to Calculate eNPS</h2>
        <p>Similar to customer NPS, eNPS is based on a single question: "On a scale of 0-10, how likely are you to recommend our company as a place to work?" The scoring categories are:</p>
        <ul>
          <li>Promoters (9-10): Highly engaged employees who actively promote your company</li>
          <li>Passives (7-8): Satisfied but not enthusiastic employees</li>
          <li>Detractors (0-6): Dissatisfied employees who might spread negative feedback</li>
        </ul>

        <h2>Impact on Company Culture</h2>
        <p>Regular eNPS measurement can significantly impact your organization in several ways:</p>
        <ul>
          <li>Early Problem Detection: Identify issues before they lead to turnover</li>
          <li>Improved Retention: Address concerns proactively to keep valuable team members</li>
          <li>Enhanced Recruitment: High eNPS can attract top talent</li>
          <li>Better Performance: Engaged employees typically deliver better results</li>
        </ul>

        <h2>Best Practices for Implementation</h2>
        <p>To get the most value from eNPS:</p>
        <ol>
          <li>Maintain Anonymity: Ensure responses are anonymous to get honest feedback</li>
          <li>Regular Surveys: Conduct surveys quarterly to track trends</li>
          <li>Follow-up Questions: Include open-ended questions for detailed feedback</li>
          <li>Quick Action: Respond to feedback promptly to show you value employee input</li>
          <li>Transparent Communication: Share results and action plans with the team</li>
        </ol>

        <h2>Common Challenges and Solutions</h2>
        <p>When implementing eNPS, organizations often face several challenges:</p>
        <ul>
          <li>Low Response Rates: Make surveys easily accessible and explain their importance</li>
          <li>Survey Fatigue: Keep surveys short and focus on key metrics</li>
          <li>Lack of Action: Create clear action plans based on feedback</li>
          <li>Fear of Negative Feedback: Foster a culture of open communication</li>
        </ul>

        <h2>Using eNPS Data Effectively</h2>
        <p>The true value of eNPS comes from how you use the data:</p>
        <ul>
          <li>Track trends over time to measure the impact of changes</li>
          <li>Compare scores across departments to identify best practices</li>
          <li>Use insights to improve onboarding and training programs</li>
          <li>Develop targeted retention strategies</li>
        </ul>

        <h2>Conclusion</h2>
        <p>eNPS is a powerful tool for understanding and improving employee satisfaction. When implemented correctly, it can help create a more engaged, productive, and loyal workforce. Remember that the score itself is just the beginning – the real value comes from the actions you take based on the feedback received.</p>
      `
    }
  },
  back: 'Back'
}; 