import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useLanguage } from '../../context/LanguageContext';
import { LanguageSwitcher } from '../LanguageSwitcher';
import { Language } from '../../types';
import './ProjectPage.css';

type ProjectId = 'yaktobi' | 'zapytai' | 'inprogress';

const projectsData: Record<ProjectId, {
  title: Record<Language, string>;
  shortDescription: Record<Language, string>;
  fullDescription: Record<Language, string>;
  image: string;
  gallery: Array<{ src: string; alt: string; }>;
}> = {
  yaktobi: {
    title: {
      en: 'Yaktobi',
      ua: 'Yaktobi'
    },
    shortDescription: {
      en: 'Our service helps analyze the customer journey and gather in-depth analytics for both online and offline businesses.[Sold in 2024]',
      ua: 'Наш сервіс допомагає аналізувати шлях клієнта та збирати глибоку аналітику для онлайн та офлайн бізнесу.[Продано в 2024]'
    },
    fullDescription: {
      en: `Yaktobi is a comprehensive analytics platform that revolutionizes how businesses understand their customers. 
      Our solution combines advanced tracking mechanisms with intuitive visualization tools to provide actionable insights. 
      By analyzing customer behavior across multiple touchpoints, businesses can optimize their marketing strategies and improve customer experience. 
      The platform offers real-time data processing, customizable dashboards, and integration with popular CRM systems. 
      Whether you're running an e-commerce store or managing a retail chain, Yaktobi provides the tools needed to make data-driven decisions 
      and stay ahead of market trends. Our AI-powered predictive analytics help identify potential opportunities and challenges before they arise.`,
      ua: `Yaktobi - це комплексна аналітична платформа, яка революціонізує спосіб розуміння клієнтів бізнесом. 
      Наше рішення поєднує передові механізми відстеження з інтуїтивно зрозумілими інструментами візуалізації для отримання практичних висновків. 
      Аналізуючи поведінку клієнтів через різні точки контакту, бізнес може оптимізувати сої маркетингові стратегії та покращити досвід клієнтів. 
      Платформа пропонує обробку даних в реальному часі, настроювані дашборди та інтеграцію з популярними CRM-системами. 
      Незалежно від того, чи керуєте ви інтернет-магазином чи мережею роздрібних магазинів, Yaktobi надає інструменти, необхідні для прийняття рішень на основі даних 
      та випередження ринкових тенденцій. Наша аналітика на базі ШІ допомагає виявляти потенційні можливості та виклики ще до їх виникнення.`
    },
    image: '/images/yaktobi/main.png',
    gallery: [
      { src: '/images/yaktobi/ask.png', alt: 'Ask Feature' },
      { src: '/images/yaktobi/eNPS.png', alt: 'eNPS Analytics' },
      { src: '/images/yaktobi/Nps.png', alt: 'NPS Dashboard' },
      { src: '/images/yaktobi/qr.png', alt: 'QR Code Feature' },
      { src: '/images/yaktobi/store.png', alt: 'Store Analytics' },
      { src: '/images/yaktobi/nps2.png', alt: 'NPS Insights' }
    ]
  },
  zapytai: {
    title: { en: 'ZapytAI', ua: 'ZapytAI' },
    shortDescription: {
      en: 'AI-powered customer service platform',
      ua: 'Платформа обслуговування клієнтів на базі ШІ'
    },
    fullDescription: {
      en: 'ZapytAI description...',
      ua: 'Опис ZapytAI...'
    },
    image: '/images/zapytai/main.png',
    gallery: []
  },
  inprogress: {
    title: { en: 'In Progress', ua: 'В розробці' },
    shortDescription: {
      en: 'Coming soon',
      ua: 'Скоро буде'
    },
    fullDescription: {
      en: 'Project in development...',
      ua: 'Проект в розробці...'
    },
    image: '/images/inprogress/main.png',
    gallery: []
  }
};

export const ProjectPage: React.FC = () => {
  const { projectId } = useParams<{ projectId: ProjectId }>();
  const { language, translations } = useLanguage();
  const navigate = useNavigate();

  useEffect(() => {
    if (projectId && projectsData[projectId]) {
      const authorName = language === 'ua' ? 'Антон Коваленко' : 'Anton Kovalenko';
      document.title = `${projectsData[projectId].title[language]} | ${authorName}`;
    }
  }, [projectId, language, translations]);

  const handleBack = () => {
    navigate(`/?lang=${language}`);
  };

  if (!projectId || !projectsData[projectId]) {
    return <div>Project not found</div>;
  }

  const project = projectsData[projectId];

  return (
    <>
      <button 
        className="back-button"
        onClick={handleBack}
      >
        {translations?.[language]?.back || 'Back'}
      </button>
      
      <LanguageSwitcher />
      <div className="project-page">
        <h1>{project.title[language]}</h1>
        <p className="short-description">
          {language === 'en' ? (
            <>
              Our service helps analyze the customer journey and gather in-depth analytics for both online and offline businesses.
              <span className="project-status">[Sold in 2024]</span>
            </>
          ) : (
            <>
              Наш сервіс допомагає аналізувати шлях клієнта та збирати глибоку аналітику для онлайн та офлайн бізнесу.
              <span className="project-status">[Продано в 2024]</span>
            </>
          )}
        </p>
        <img 
          src={project.image}
          alt={project.title[language]}
          className="project-image"
        />
        <p className="full-description">{project.fullDescription[language]}</p>
        <div className="gallery">
          {project.gallery.map((image, index) => (
            <img 
              key={index}
              src={image.src}
              alt={image.alt}
              className="gallery-image"
            />
          ))}
        </div>
      </div>
    </>
  );
}; 