import React, { useEffect } from 'react';
import { useLanguage } from '../../context/LanguageContext';
import { BlogCard } from '../BlogCard';
import { LanguageSwitcher } from '../LanguageSwitcher';
import { useNavigate } from 'react-router-dom';
import './BlogPage.css';
import { Helmet } from 'react-helmet';

export const BlogPage: React.FC = () => {
  const { translations, language } = useLanguage();
  const blogTitle = translations?.[language]?.blog;
  const blogPosts = translations?.[language]?.blogPosts;
  const authorName = translations?.[language]?.authorName || 'Anton Kovalenko';
  const backText = translations?.[language]?.back || 'Back';
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `${blogTitle} | ${authorName}`;
  }, [language, blogTitle, authorName]);

  const getPostUrl = (postId: string) => {
    console.log('Creating URL for postId:', postId);
    
    const urlMapping: Record<string, string> = {
      'first_post': 'what-is-nps-how-to-calculate-and-use-it',
      'second_post': 'building-saas-products',
      'third_post': 'enps-measuring-team-satisfaction'
    };
    
    const url = `/blog/${urlMapping[postId] || postId}?lang=${language}`;
    console.log('Generated URL:', url);
    return url;
  };

  const handleBack = () => {
    navigate(`/?lang=${language}`);
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://antonkovalenko.com/blog" />
        <meta property="og:url" content="https://antonkovalenko.com/blog" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`${blogTitle} | ${authorName}`} />
      </Helmet>
      <button 
        className="back-button" 
        onClick={handleBack}
      >
        {backText}
      </button>
      <LanguageSwitcher />
      <div className="blog-section">
        <h1>{blogTitle}</h1>
        <div className="blog-posts">
          {Object.entries(blogPosts || {}).map(([id, post]) => (
            <BlogCard
              key={id}
              post={id}
              href={getPostUrl(id)}
            />
          ))}
        </div>
      </div>
    </>
  );
}; 