import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ProjectCard } from '../ProjectCard';
import { useLanguage } from '../../context/LanguageContext';
import './ProjectsSection.css';

interface ProjectsSectionProps {
  onBlogClick: () => void;
}

export const ProjectsSection: React.FC<ProjectsSectionProps> = ({ onBlogClick }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { translations, language } = useLanguage();
  const projectsTitle = translations?.[language]?.projects;
  const blogTitle = translations?.[language]?.blog;
  const projectCards = translations?.[language]?.projectCards;

  const isProjects = location.pathname === '/';
  const isBlog = location.pathname === '/blog';

  if (!translations?.[language]) {
    console.log('No translations found for:', language);
    return null;
  }

  return (
    <div className="projects-section">
      <div className="section-header">
        <h1 
          onClick={() => navigate('/')} 
          className={isProjects ? 'active' : ''}
        >
          {projectsTitle}
        </h1>
        <h1 
          onClick={onBlogClick} 
          className={isBlog ? 'active' : ''}
        >
          {blogTitle}
        </h1>
      </div>
      
      {projectCards?.zapytai && (
        <ProjectCard 
          project="zapytai" 
          href="https://zapytai.antonkovalenko.com" 
        />
      )}
      
      {projectCards?.yaktobi && (
        <ProjectCard 
          project="yaktobi"
          href="/project/yaktobi"
        />
      )}
      
      {projectCards?.inprogress && (
        <ProjectCard 
          project="inprogress" 
        />
      )}
    </div>
  );
}; 