import React from 'react';
import { useLanguage } from '../../context/LanguageContext';
import './LanguageSwitcher.css';

export const LanguageSwitcher: React.FC = () => {
  const { language, toggleLanguage } = useLanguage();

  return (
    <div className="language-switcher">
      <button 
        className={`language-btn ${language === 'en' ? 'active' : ''}`}
        onClick={toggleLanguage}
      >
        En
      </button>
      <span className="language-divider">|</span>
      <button 
        className={`language-btn ${language === 'ua' ? 'active' : ''}`}
        onClick={toggleLanguage}
      >
        Ua
      </button>
    </div>
  );
}; 