import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LanguageSwitcher } from '../LanguageSwitcher';
import { ProjectsSection } from '../ProjectsSection';
import { Profile } from '../Profile';
import { useLanguage } from '../../context/LanguageContext';
import { useTheme } from '../../context/ThemeContext';
import './MainPage.css';
import { Helmet } from 'react-helmet';

export const MainPage: React.FC = () => {
  const { language, translations } = useLanguage();
  const { theme, toggleTheme } = useTheme();
  const authorName = translations?.[language]?.authorName || 'Anton Kovalenko';
  const navigate = useNavigate();

  useEffect(() => {
    document.title = translations[language].profile.title;
  }, [language, translations]);

  const handleBlogClick = () => {
    navigate(`/blog?lang=${language}`);
  };

  return (
    <>
      <Helmet>
        <meta property="og:url" content="https://antonkovalenko.com" />
        <meta property="og:title" content={`${authorName} | ${translations?.[language]?.profile?.title}`} />
        <meta property="og:description" content={translations?.[language]?.profile?.description} />
        <meta property="og:image" content="https://antonkovalenko.com/images/profile.jpg" />
        
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "Person",
            "name": "Anton Kovalenko",
            "url": "https://antonkovalenko.com",
            "image": "https://antonkovalenko.com/images/profile.jpg",
            "description": "${translations?.[language]?.profile?.description}",
            "sameAs": [
              "https://www.linkedin.com/in/anton-kovalenko-head-of-product/",
              "https://twitter.com/antonecommerce"
            ],
            "jobTitle": "${translations?.[language]?.profile?.title}",
            "knowsAbout": ["Product Development", "MarTech", "Startups", "Crypto"]
          }`}
        </script>
      </Helmet>
      <LanguageSwitcher />
      <div className="container">
        <ProjectsSection onBlogClick={handleBlogClick} />
        <Profile />
      </div>
      <button 
        className="theme-toggle" 
        onClick={toggleTheme}
        aria-label="Toggle theme"
      >
        {theme === 'light' ? '🌙' : '☀️'}
      </button>
    </>
  );
}; 